<template>
  <div class="container-fluid card-panel bg-white shadow-hover shadow-sm mt-0" :id="idSecao">
    <div class="row">
      <div class="col-12">
        <h1 class="h1">Dados Gerais</h1>
      </div>
    </div>

    <div class="row">
      <div ref="descricao" class="col-12">
        <label class="input-required label-cadastro-balancos">Descrição</label>
        <input
          v-focus
          class="form-control form-control-sm"
          type="text"
          name="name"
          v-model="dadosSelecionados.descricao"
          maxlength="100"
          autocomplete="off"
        />
        <input
          type="hidden"
          :class="{
            'is-invalid': errosValidacao['descricao'],
            'form-control': true,
          }"
          autocomplete="off"
        />
        <div class="invalid-feedback">
          <span v-html="errosValidacao['descricao']"></span>
        </div>
      </div>
    </div>

    <div class="row">
      <div ref="datalancamento" class="col-sm-12">
        <label class="input-required label-cadastro-balancos" for="btnDataLancamento" id="lancamentoLabel">
          Data Lançamento
        </label>
        <div class="input-group input-group-sm date">
          <DatePicker
            ref="inputdataLancamento"
            class="col-sm-2 form-control form-control-sm"
            name="datePickerDataLancamento"
            nome="datePickerDataLancamento"
            botao="btnDataLancamento"
            v-model="dadosSelecionados.dataLancamento"
          ></DatePicker>
          <span class="input-group-append" id="btnDataLancamento">
            <div class="input-group-text input-group-sm bg-transparent">
              <i class="icon icon-lx-calendar"></i>
            </div>
          </span>
        </div>
        <input
          type="hidden"
          :class="{
            'is-invalid': errosValidacao['datalancamento'],
            'form-control': true,
          }"
          autocomplete="off"
        />
        <div class="invalid-feedback">
          <span v-html="errosValidacao['datalancamento']"></span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <label class="input-required label-cadastro-balancos">Depósito</label>
      </div>
      <div ref="deposito" class="col-sm-12">
        <VueMultiselect
          v-model="dadosSelecionados.deposito"
          :options="listaDepositos"
          :multiple="false"
          :close-on-select="true"
          :allow-empty="true"
          :group-select="false"
          :clear-on-select="true"
          :preserve-search="false"
          :custom-label="customLabel"
          placeholder="Selecione"
          :option-height="10"
          selected-label="Selecionado"
          select-label="Selecionar"
          deselect-label="Remover"
          label="Descricao"
          track-by="Id"
          @input="resetLocalizacao()"
          :preselect-first="false"
        >
          <template slot="selection" slot-scope="select">
            <span class="multiselect__single" v-if="select.values.length == 1 &amp;&amp; !select.isOpen">
              <div v-for="(item, idx) in select.values" :key="idx" style="float: left">
                {{ item.Id + '-' + item.Descricao }}
              </div>
            </span>
          </template>
          <span slot="noResult">Nenhum registro encontrado</span>
          <span slot="noOptions">Selecione um registro</span>
        </VueMultiselect>
        <input
          type="hidden"
          :class="{
            'is-invalid': errosValidacao['deposito'],
            'form-control': true,
          }"
        />
        <div class="invalid-feedback mt-2">
          <span v-html="errosValidacao['deposito']"></span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <label class="input label-cadastro-balancos">Fornecedor</label>
      </div>
      <div ref="fornecedor" class="col-sm-12">
        <VueMultiselect
          v-model="dadosSelecionados.fornecedores"
          :options="listaFornecedores"
          :multiple="true"
          :close-on-select="false"
          :allow-empty="true"
          :group-select="false"
          :clear-on-select="true"
          :preserve-search="false"
          placeholder="Selecione"
          :option-height="10"
          :custom-label="customLabelFornecedor"
          selected-label="Selecionado"
          select-label="Selecionar"
          deselect-label="Remover"
          label="Descricao"
          track-by="Codigo"
          :preselect-first="false"
        >
          <span slot="noResult">Nenhum registro encontrado</span>
          <span slot="noOptions">Selecione um registro</span>
        </VueMultiselect>
        <input
          type="hidden"
          :class="{
            'is-invalid': errosValidacao['fornecedor'],
            'form-control': true,
          }"
        />
        <div class="invalid-feedback">
          <span v-html="errosValidacao['fornecedor']"></span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <label class="input-required label-cadastro-balancos">Seções</label>
      </div>
      <div ref="secoes" class="col-sm-12">
        <VueMultiselect
          v-model="dadosSelecionados.secoes"
          :options="listaSecoes"
          :multiple="true"
          :allow-empty="true"
          :close-on-select="false"
          :is-invalid="errosValidacao['secoes']"
          :group-select="false"
          :clear-on-select="true"
          :preserve-search="true"
          placeholder="Selecione"
          :option-height="10"
          :custom-label="customLabelSecao"
          selected-label="Selecionado"
          select-label="Selecionar"
          deselect-label="Remover"
          label="Descricao"
          track-by="Id"
          :preselect-first="false"
          @search-change="(searchQuery, id) => (descricaoNovaSecao = searchQuery)"
          @input="depositoControlaLocalizacao && setLocalizacao()"
          @remove="removeLocalizacao"
        >
          <span slot="noResult"
            >Nenhum registro encontrado
            <button class="criar-nova-secao-button" @click="emiteEventoCadastrarSecao" v-if="permissoesUsuario.PermiteIncluirSecoes">
              Clique aqui para cadastrar uma nova seção.
            </button>
          </span>
          <span slot="noOptions">Selecione um registro</span>
        </VueMultiselect>
        <input
          type="hidden"
          :class="{
            'is-invalid': errosValidacao['secoes'],
            'form-control mt-1': true,
          }"
        />
        <div class="invalid-feedback mt-2">
          <span v-html="errosValidacao['secoes']"></span>
        </div>
      </div>
    </div>
    <!--MJ-22173-->
    <div v-if="depositoControlaLocalizacao && permissoesUsuario.UtilizaModuloWMS">
      <div v-for="(secao, index) in dadosSelecionados.secoes" :key="index">
        <div class="px-2 pb-2 my-2 border bg-white shadow-hover shadow-sm">
          <span class="px-2 badge badge-primary"> {{ secao.Descricao }} ({{ secao.Id }}) </span>

          <div class="row">
            <div class="col-sm-6">
              <label class="label-cadastro-balancos">Localização Inicial</label>
            </div>
            <div class="col-sm-6">
              <label class="label-cadastro-balancos">Localização Final</label>
            </div>
            <!--Localizacao Inicial-->
            <div class="col-sm-6" :ref="'localizacaoInicial-' + secao.Id">
              <LocalizacaoWMS
                :idDeposito="dadosSelecionados.deposito.Id"
                :idSecao="secao.Id"
                :localizacaoSelecionada="localizacaoUpdate(secao.Id, 1)"
                @input="setLocalizacao()"
                v-model="rangeLocalizacaoInicial[secao.Id]"
              >
              </LocalizacaoWMS>
              <input
                type="hidden"
                :class="{ 'is-invalid': errosValidacao['localizacaoInicial-' + secao.Id], 'form-control': true }"
              />
              <div class="invalid-feedback">
                <span v-html="errosValidacao['localizacaoInicial-' + secao.Id]"></span>
              </div>
            </div>
            <!--Localizacao Final-->
            <div class="col-sm-6" :ref="'localizacaoFinal-' + secao.Id">
              <LocalizacaoWMS
                :idDeposito="dadosSelecionados.deposito.Id"
                :idSecao="secao.Id"
                :localizacaoSelecionada="localizacaoUpdate(secao.Id, 2)"
                @input="setLocalizacao()"
                v-model="rangeLocalizacaoFinal[secao.Id]"
              >
              </LocalizacaoWMS>
              <input
                type="hidden"
                :class="{ 'is-invalid': errosValidacao['localizacaoFinal-' + secao.Id], 'form-control': true }"
              />
              <div class="invalid-feedback">
                <span v-html="errosValidacao['localizacaoFinal-' + secao.Id]"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MJ-26196 -->
    <div class="row mt-2" v-if="!permissoesUsuario.UtilizaModuloWMS">
      <div class="col-sm-12">
        <div class="custom-control custom-checkbox">
          <input
            class="custom-control-input pt-2"
            id="zerarQuantidades"
            type="checkbox"
            @change="dadosSelecionados.desconsiderarDiferencas = false"
            v-model="dadosSelecionados.zerarQuantidades"
          />
          <label class="custom-control-label" for="zerarQuantidades">Zerar as quantidades</label>
          <span class="ml-2 tooltip-preferencias" v-if="!isMobile">
            <i class="fa fa-info-circle fa-lg" aria-hidden="true" style="color: #5b2e90"></i>
            <span class="tooltiptext ml-2">
              <div class="tooltip-content fz-90">
                <InfotipZerarQuantidade />
              </div>
            </span>
          </span>
          <span class="ml-2" v-else @click="showTooltipZerar = showTooltipZerar ? false : true">
            <i class="fa fa-info-circle fa-lg" aria-hidden="true" style="color: #5b2e90"></i>
          </span>
        </div>
        <div
          class="tooltip-content fz-70 bg-default p-2"
          v-if="showTooltipZerar"
          @click="showTooltipZerar = showTooltipZerar ? false : true"
        >
          <InfotipZerarQuantidade />
        </div>
        <div class="custom-control custom-checkbox" v-if="utilizaDesconsiderarDiferencas">
          <input
            class="custom-control-input pt-2"
            id="desconsiderarDiferencas"
            type="checkbox"
            @change="dadosSelecionados.zerarQuantidades = false"
            v-model="dadosSelecionados.desconsiderarDiferencas"
          />
          <label class="custom-control-label" for="desconsiderarDiferencas">Desconsiderar as diferenças</label>
          <span class="ml-2 tooltip-preferencias" v-if="!isMobile">
            <i class="fa fa-info-circle fa-lg" aria-hidden="true" style="color: #5b2e90"></i>
            <span class="tooltiptext ml-2">
              <div class="tooltip-content fz-90">
                <InfoDesconsiderarDiferenca />
              </div>
            </span>
          </span>
          <span class="ml-2" v-else @click="showTooltipDesconsiderarDiferenca = showTooltipDesconsiderarDiferenca ? false : true">
            <i class="fa fa-info-circle fa-lg" aria-hidden="true" style="color: #5b2e90"></i>
          </span>
        </div>
        <div
          class="tooltip-content fz-70 bg-default p-2"
          v-if="showTooltipDesconsiderarDiferenca"
          @click="showTooltipDesconsiderarDiferenca = showTooltipDesconsiderarDiferenca ? false : true"
        >
          <InfoDesconsiderarDiferenca />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import VueMultiselect from 'vue-multiselect';

  import DatePicker from '@/components/shared/PikadayVueDatepicker';

  import dateUtils from '@/common/date';
  import swal from '@/common/alerts';
  import LocalizacaoWMS from '@/components/shared/localizacao-wms/LocalizacoesWMS.vue';
  import VTooltip from 'v-tooltip';
  Vue.use(VTooltip);

  const InfotipZerarQuantidade = () => {
    return (
      <div>
        <p>
          Com esta opção marcada, o sistema adicionará ao final do balanço todos os produtos que não foram inseridos na seção mas
          que contém <b>saldo difente de zero (0)</b>, respeitando os filtros utilizados no cadastro do balanço (Ex.: Estrutura
          Mercadológica).
        </p>
        <p>
          Ao finalizar o balanço, o saldo desses produtos serão automaticamente <b>zerados</b>.
        </p>
      </div>
    );
  };
  const InfoDesconsiderarDiferenca = () => {
    return (
      <div>
        <p>Com esta opção marcada, o sistema irá acrescentar ao saldo existente a quantidade informada dos itens inseridos.</p>
        <p>
          Ex.: <b>Saldo Atual ERP</b> = 5, <b>Saldo Informado</b> = 5, <b>Saldo Final</b> será igual a 10.
        </p>
      </div>
    );
  };

  export default {
    name: 'DadosGeraisBalancos',

    components: {
      VueMultiselect,
      DatePicker,
      LocalizacaoWMS,
      InfotipZerarQuantidade,
      InfoDesconsiderarDiferenca,
    },

    directives: {
      focus: {
        inserted: (el) => el.focus(),
      },
    },

    props: {
      listaFornecedores: {
        type: Array,
        default: () => [],
      },
      alteracaoDeBalanco: {
        type: Boolean,
        default: false,
      },
      dadosBalancoParaAlterar: {
        type: Object,
        default: () => {},
      },
      listaDepositos: {
        type: Array,
        default: () => [],
      },
      listaSecoes: {
        type: Array,
        default: () => [],
      },
      legendasNomes: {
        type: Object,
        default: () => {},
      },
      idSecao: {
        type: String,
        default: 'secaoDadosGerais',
      },
      permissoesUsuario: {
        type: Object,
        default: () => {},
      },
      utilizaDesconsiderarDiferencas: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        errosValidacao: {},
        descricaoNovaSecao: '',
        dadosSelecionados: {
          idBalanco: 0,
          descricao: null,
          dataLancamento: null,
          fornecedores: [],
          deposito: null,
          secoes: [],
          listaSecoesBalanco: [],
          zerarQuantidades: null,
          desconsiderarDiferencas: null,
        },
        rangeLocalizacaoInicial: {},
        rangeLocalizacaoFinal: {},
        secoesUpdate: [],
        showTooltipZerar: null,
        showTooltipDesconsiderarDiferenca: null,
      };
    },

    watch: {
      dadosBalancoParaAlterar() {
        this.setaDadosParaBalancoAlterar();
      },
    },
    computed: {
      depositoControlaLocalizacao() {
        return this.dadosSelecionados?.deposito?.ControlaLocalizacaoWMS;
      },
      isMobile() {
        if (navigator.userAgent.indexOf('Mobile') > 0) {
          return true;
        } else {
          return false;
        }
      },
    },
    methods: {
      converteData(valor) {
        var dia = valor.split('/')[0];
        var mes = Number(valor.split('/')[1] - 1);
        var ano = valor.split('/')[2];
        return new Date(ano, mes, dia, 0, 0, 0);
      },
      obterCommandDTO() {
        const {
          idBalanco,
          descricao,
          dataLancamento,
          fornecedores,
          deposito,
          secoes,
          zerarQuantidades,
          desconsiderarDiferencas,
        } = this.dadosSelecionados;

        const idsFornecedores = fornecedores.map(({ Codigo }) => Codigo);
        const idsSecoes = secoes.map(({ Id }) => Id);

        let command = {
          IdBalanco: idBalanco,
          Descricao: descricao,
          DataLancamento: this.converteData(dataLancamento),
          IdDeposito: deposito.Id,
          IdsFornecedores: idsFornecedores,
          IdStatusBalanco: 1,
          ZerarQuantidades: zerarQuantidades,
          DesconsiderarDiferencas: desconsiderarDiferencas,
        };

        if (deposito.ControlaLocalizacaoWMS) {
          const listSecoesBalanco = secoes.map((secao) => {
            return {
              IdSecao: secao.Id,
              IdLocalizacaoInicial: secao.idLocalizacaoInicial,
              IdLocalizacaoFinal: secao.idLocalizacaoFinal,
            };
          });

          command = { ...command, SecoesBalanco: listSecoesBalanco };
        } else {
          command = { ...command, Secoes: idsSecoes };
        }

        return command;
      },
      validarDados() {
        var ref = this.$refs;

        this.errosValidacao = {};

        const isRequired = 'Informe o Campo';
        var erroMsg = '';

        const { descricao, deposito, dataLancamento, secoes } = this.dadosSelecionados;

        if (!descricao) {
          erroMsg = this.customErroMsg(isRequired, 'Descrição');
          this.errosValidacao['descricao'] = erroMsg;
        }

        if (!deposito) {
          erroMsg = this.customErroMsg(isRequired, 'Dep\u00f3sito');
          this.errosValidacao['deposito'] = erroMsg;
        }

        if (!dataLancamento) {
          erroMsg = this.customErroMsg(isRequired, 'Data lan\u00e7amento');
          this.errosValidacao['datalancamento'] = erroMsg;
        }

        if (!secoes.length > 0) {
          erroMsg = this.customErroMsg(isRequired, 'Se\u00e7\u00f5es');
          this.errosValidacao['secoes'] = erroMsg;
        }

        if (deposito?.ControlaLocalizacaoWMS && this.permissoesUsuario.UtilizaModuloWMS) {
          let msgLocalizacaoInicial = 'Localização Inicial incompleta.';
          let msgLocalizacaoFinal = 'Localização Final incompleta.';

          let algumaLocalizacaoPreenchida =
            secoes.length > 1 && secoes.some((s) => s.siglaLocalizacaoInicial || s.siglaLocalizacaoFinal);
          let algumaLocalizacaoNaoPreenchida =
            secoes.length > 1 && secoes.some((s) => !s.siglaLocalizacaoInicial || !s.siglaLocalizacaoFinal);
          let localizacoesRangeIlimitado = secoes.filter((s) => !s.siglaLocalizacaoInicial && !s.siglaLocalizacaoFinal);

          secoes.forEach((secao) => {
            // Se tiver Sigla mas nao tiver o IdLocalizacao, significa que a localização está incompleta
            // Para se tornar uma localização válida é necessário preencher todos os níveis que formam o cadastro desta localização
            if (secao.siglaLocalizacaoInicial && !secao.idLocalizacaoInicial) {
              this.errosValidacao['localizacaoInicial-' + secao.Id] = msgLocalizacaoInicial;
            }
            if (secao.siglaLocalizacaoFinal && !secao.idLocalizacaoFinal) {
              this.errosValidacao['localizacaoFinal-' + secao.Id] = msgLocalizacaoFinal;
            }

            if (
              (secao.idLocalizacaoInicial || secao.siglaLocalizacaoInicial) &&
              !secao.idLocalizacaoFinal &&
              !secao.siglaLocalizacaoFinal
            ) {
              this.errosValidacao['localizacaoFinal-' + secao.Id] = msgLocalizacaoFinal;
            }
            if (
              (secao.siglaLocalizacaoFinal || secao.idLocalizacaoFinal) &&
              !secao.idLocalizacaoInicial &&
              !secao.siglaLocalizacaoInicial
            ) {
              this.errosValidacao['localizacaoInicial-' + secao.Id] = msgLocalizacaoInicial;
            }

            // Só é permitido cadastrar uma seção com range ilimitado e quando um range estiver informado, todas as seções são obrigatórias a possuir um range válido
            if ((algumaLocalizacaoPreenchida && algumaLocalizacaoNaoPreenchida) || localizacoesRangeIlimitado.length > 1) {
              this.errosValidacao['localizacaoInicial-' + secao.Id] = msgLocalizacaoInicial;
              this.errosValidacao['localizacaoFinal-' + secao.Id] = msgLocalizacaoFinal;
            }
          });
        }

        if (Object.keys(this.errosValidacao).length > 0) {
          let el = ref[Object.keys(this.errosValidacao)[0]];
          if (secoes.some((s) => s.siglaLocalizacaoInicial || s.siglaLocalizacaoFinal)) {
            el[0].scrollIntoView();
          } else el.scrollIntoView();
        }

        return Promise.resolve(Object.keys(this.errosValidacao).length === 0);
      },
      emiteEventoCadastrarSecao() {
        swal.exibirPergunta(
          'Cadastro De Seção',
          `Tem certeza que deseja cadastrar a seção <strong>${this.descricaoNovaSecao}</strong>?`,
          (cadastrarNovaSecao) => {
            if (cadastrarNovaSecao) {
              this.$emit('cadastrar-secao', this.descricaoNovaSecao);
              this.descricaoNovaSecao = '';
            }
          },
        );
      },
      customLabel({ NomeDeposito, Id }) {
        return `${NomeDeposito} (${Id})`;
      },
      customLabelSecao({ Descricao, Id }) {
        return `${Descricao} (${Id})`;
      },
      customLabelFornecedor({ Codigo, NomeCliente }) {
        return `${Codigo} - ${NomeCliente}`;
      },
      customErroMsg(erro, campo) {
        return `${erro}: ${campo}`;
      },
      setaDadosParaBalancoAlterar() {
        if (this.alteracaoDeBalanco && this.dadosBalancoParaAlterar) {
          const {
            IdBalanco,
            Descricao,
            DataLancamento,
            IdDeposito,
            IdsFornecedores,
            Secoes,
            ZerarQuantidades,
            DesconsiderarDiferencas,
          } = this.dadosBalancoParaAlterar;

          this.dadosSelecionados.idBalanco = IdBalanco;
          this.dadosSelecionados.descricao = Descricao;
          this.dadosSelecionados.dataLancamento = dateUtils.obterDataParaDatepicker(DataLancamento);
          this.dadosSelecionados.deposito = this.listaDepositos.find(({ Id }) => IdDeposito == Id);
          this.dadosSelecionados.fornecedores = this.listaFornecedores.filter(({ Codigo }) => IdsFornecedores.includes(Codigo));
          this.dadosSelecionados.secoes = Secoes.filter(({ Descricao }) => Descricao);
          this.dadosSelecionados.zerarQuantidades = ZerarQuantidades;
          this.dadosSelecionados.desconsiderarDiferencas = DesconsiderarDiferencas;

          //MODAJOI-22173 - Set Dados das Localizacoes
          if (this.dadosSelecionados.deposito.ControlaLocalizacaoWMS) {
            this.dadosSelecionados.secoes.map((secao) => {
              let secaoOrigem = Secoes.filter((f) => f.Id == secao.Id)[0];
              let _siglaLocalizacaoInicial =
                secaoOrigem.LocalizacaoInicial &&
                secaoOrigem.LocalizacaoInicial.map((p) => {
                  return p.Sigla;
                }).pop();
              let _siglaLocalizacaoFinal =
                secaoOrigem.LocalizacaoFinal &&
                secaoOrigem.LocalizacaoFinal.map((p) => {
                  return p.Sigla;
                }).pop();

              secao = {
                siglaLocalizacaoInicial: _siglaLocalizacaoInicial,
                siglaLocalizacaoFinal: _siglaLocalizacaoFinal,
                idLocalizacaoInicial: secaoOrigem.IdLocalizacaoInicial,
                idLocalizacaoFinal: secaoOrigem.IdLocalizacaoFinal,
              };

              this.rangeLocalizacaoInicial[secao.Id] = {
                IdLocalizacao: secaoOrigem.IdLocalizacaoInicial,
                Sigla: _siglaLocalizacaoInicial,
              };

              this.rangeLocalizacaoFinal[secao.Id] = {
                IdLocalizacao: secaoOrigem.IdLocalizacaoFinal,
                Sigla: _siglaLocalizacaoFinal,
              };
            });
          }
        }
      },
      setLocalizacao() {
        this.dadosSelecionados.secoes.map((secao) => {
          secao.idLocalizacaoInicial = this.rangeLocalizacaoInicial[secao.Id]?.IdLocalizacao;
          secao.siglaLocalizacaoInicial = this.rangeLocalizacaoInicial[secao.Id]?.Sigla;
          secao.idLocalizacaoFinal = this.rangeLocalizacaoFinal[secao.Id]?.IdLocalizacao;
          secao.siglaLocalizacaoFinal = this.rangeLocalizacaoFinal[secao.Id]?.Sigla;
        });
      },
      removeLocalizacao(removedOption) {
        delete this.rangeLocalizacaoInicial[removedOption.Id];
        delete this.rangeLocalizacaoFinal[removedOption.Id];
      },
      resetLocalizacao() {
        this.dadosSelecionados.secoes = [];
        this.rangeLocalizacaoInicial = {};
        this.rangeLocalizacaoFinal = {};
      },

      localizacaoUpdate(idSecao, target) {
        if (this.alteracaoDeBalanco) {
          let localizacao = this.dadosSelecionados.secoes.filter((secao) => secao.Id == idSecao)[0];

          if (!localizacao.LocalizacaoInicial) return [];

          let listaLocalizacoes = [];
          if (target === 1) {
            localizacao.LocalizacaoInicial.forEach((x) => {
              listaLocalizacoes[x.Nivel] = { Nivel: x.Nivel, Sigla: x.Sigla };
            });
          } else {
            localizacao.LocalizacaoFinal.forEach((x) => {
              listaLocalizacoes[x.Nivel] = { Nivel: x.Nivel, Sigla: x.Sigla };
            });
          }
          return listaLocalizacoes;
        }
      },
    },

    mounted() {
      this.setaDadosParaBalancoAlterar();
    },
  };
</script>

<style scoped>
  .label-cadastro-balancos {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }

  .criar-nova-secao-button {
    border: none;
    background: none;
    padding: 0 1px;
    margin: 0;
    cursor: pointer;
    font-weight: 600;
    color: #626262;
    font-style: Dosis, sans-serif;
    transition: color 1s;
  }

  .criar-nova-secao-button:hover {
    color: #3b3b3b;
  }

  .multiselect {
    height: 34px;
  }
  .tooltip-preferencias .tooltiptext {
    visibility: hidden;
    width: max-content;
    background-color: rgba(91, 46, 144, 1);
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    left: auto;
  }

  .tooltip-preferencias .tooltiptext::after {
    content: '';
    position: absolute;
    top: 7%;
    right: 100%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(91, 46, 144, 0.9) transparent transparent;
  }

  .tooltip-preferencias:hover .tooltiptext {
    visibility: visible;
  }

  .tooltip-content {
    min-width: 250px;
    max-width: 350px;
    text-align: justify;
    text-justify: inter-word;
  }
</style>
