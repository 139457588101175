<template>
  <div class="container-fluid overflow-overlay h-100 w-100 pb-3" id="containerCadastroBalancos">
    <template v-if="isLoading">
      <BaseLoading />
    </template>
    <div v-show="!isLoading" class="row">
      <div class="col-md-9 pb-2">
        <DadosGerais
          ref="dadosGerais"
          :lista-fornecedores="filtros.Fornecedores"
          :lista-depositos="filtros.Depositos"
          :lista-secoes="filtros.Secoes"
          :controle-tela="controleTela"
          :alteracao-de-balanco="alteracaoDeBalanco"
          :dados-balanco-para-alterar="dadosBalancoParaAlterar"
          :utiliza-desconsiderar-diferencas="utilizaDesconsiderarDiferencas"
          :permissoes-usuario="permissoesUsuario"
          @cadastrar-secao="cadastrarSecao"
        />

        <EstruturasMercadologicas
          ref="estruturaMercadologica"
          :estrutura-mercadologica="filtros"
          :legendas-nomes="legendasDinamicas"
          :controle-tela="controleTela"
          :alteracao-de-balanco="alteracaoDeBalanco"
          :dados-balanco-para-alterar="dadosBalancoParaAlterar"
        />
      </div>

      <div class="col-md-3 d-none d-md-flex fixed d-xs-none">
        <div class="container-fluid card-panel bg-white shadow-hover shadow-sm mt-0">
          <div class="row">
            <div class="col-12">
              <NavegacaoLateral :lista-de-secoes="listaDeSecoes" />
            </div>
            <div class="col-12">
              <div v-if="alteracaoDeBalanco" class="navbar-bt mt-2">
                <button type="button" class="btn btn-primary w-100" @click="alterarBalanco" :disabled="!visualizarBalanco">
                  Alterar
                </button>
              </div>
              <div v-else class="navbar-bt mt-2">
                <button type="button" class="btn btn-primary w-100" @click="validarECadastrarBalanco"> Cadastrar </button>
              </div>
              <div class="navbar-bt mt-2">
                <button v-if="visualizarBalanco" @click="voltarParaListagem" type="button" class="btn btn-default w-100">
                  Cancelar
                </button>
                <button
                  v-else
                  @click="
                    $router.push({
                      name: 'PainelBalancos',
                    })
                  "
                  type="button"
                  class="btn btn-default w-100"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';

  import NavegacaoLateral from './MenuLateral.vue';
  import DadosGerais from './DadosPrincipais.vue';
  import EstruturasMercadologicas from './EstruturasMercadologicas.vue';

  import swal from '@/common/alerts';
  import loading from '@/common/loading';
  import store from '@/store/index';

  export default {
    name: 'CadastroBalancos',

    components: {
      NavegacaoLateral,
      DadosGerais,
      EstruturasMercadologicas,
    },
    beforeRouteEnter (to, from, next) {
      if (!to.params.idBalanco && !store.state.balanco.permissoes.IncluirNovoBalanco)
        return false;
      if (to.params.idBalanco && !to.params.visualizar && !store.state.balanco.permissoes.AlterarBalanco)
        return false;
      next();
    },
    data() {
      return {
        idBalanco: 0,
        alteracaoDeBalanco: false,
        dadosBalancoParaAlterar: null,
        controleTela: {},
        legendasDinamicas: {
          CodigoAuxiliar: '',
          Classificacao: '',
          Setor: '',
          Linha: '',
          Marca: '',
          Colecao: '',
          Espessura: '',
          Grade1: '',
          Grade2: '',
        },
        filtros: {
          Fornecedores: [],
          Depositos: [],
          Secoes: [],
          Setores: [],
          Linhas: [],
          Marcas: [],
          Colecoes: [],
          Grades1: [],
          Grades2: [],
        },
        permissoesUsuario: {
          BalancoAjusteDeSaldo: false,
          PermiteIncluirSecoes: false,
          UtilizaConferenciaEmEtapas: false,
          UtilizaModuloWMS: false,
          VisualizaCusto: false,
        },
        utilizaDesconsiderarDiferencas: false,
      };
    },

    computed: {
      listaDeSecoes() {
        return [
          {
            Id: '#secaoDadosGerais',
            Titulo: 'Dados Gerais',
            Visivel: true,
          },
          {
            Id: '#secaoEstruturaMercadologica',
            Titulo: 'Estrutura Mercadológica',
            Visivel: true,
          },
        ];
      },
      isLoading() {
        return this.$store.state.uiState.isLoading;
      },
      visualizarBalanco() {
        var param = this.$route.params;
        if (!param.visualizar && (param.statusBalanco == 1 || param.statusBalanco == undefined)) return true;
        return false;
      },
    },

    mounted() {
      this.obterDadosIniciaisCadastroBalancos().then(() => {
        var idBalanco = this.$route.params.idBalanco;
        if (idBalanco != undefined && idBalanco != null) this.alteracaoDeBalanco = true;
        if (this.alteracaoDeBalanco) this.obterBalancoParaAtualizacao(idBalanco);
      });
    },

    methods: {
      async obterDadosIniciaisCadastroBalancos() {
        loading.exibir();

        return await this.$store
          .dispatch('balanco/obterDadosIniciaisCadastroBalancos')
          .then(this.popularDadosIniciais)
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          })
          .finally(() => {
            loading.ocultar();
          });
      },

      popularDadosIniciais(retorno) {
        var dadosSetor = retorno.Setores;
        var dadosLinha = retorno.Linhas;
        var dadosMarca = retorno.Marcas;
        var dadosColecao = retorno.Colecoes;
        var dadosGrade1 = retorno.Tamanhos;
        var dadosGrade2 = retorno.Cores;

        this.permissoesUsuario = retorno.PermissoesUsuario;
        this.filtros.Fornecedores = retorno.Fornecedores;
        this.filtros.Depositos = retorno.Depositos;
        this.filtros.Secoes = retorno.Secoes;
        this.utilizaDesconsiderarDiferencas = retorno.UtilizaDesconsiderarDiferencas;

        this.legendasDinamicas.Setor = dadosSetor.LegendaDinamica;
        this.legendasDinamicas.Linha = dadosLinha.LegendaDinamica;
        this.legendasDinamicas.Marca = dadosMarca.LegendaDinamica;
        this.legendasDinamicas.Colecao = dadosColecao.LegendaDinamica;
        this.legendasDinamicas.Grade1 = dadosGrade1.LegendaDinamica;
        this.legendasDinamicas.Grade2 = dadosGrade2.LegendaDinamica;

        this.filtros.Setores = this.ordenarEFiltrarEstrutura(dadosSetor.Estruturas);
        this.filtros.Linhas = this.ordenarEFiltrarEstrutura(dadosLinha.Estruturas);
        this.filtros.Marcas = this.ordenarEFiltrarEstrutura(dadosMarca.Estruturas);
        this.filtros.Colecoes = this.ordenarEFiltrarEstrutura(dadosColecao.Estruturas);
        this.filtros.Grades1 = this.ordenarEFiltrarEstrutura(dadosGrade1.Estruturas);
        this.filtros.Grades2 = this.ordenarEFiltrarEstrutura(dadosGrade2.Estruturas);

        if (this.permissoesUsuario.UtilizaModuloWMS) this.carregaLabelNiveis();
        return Promise.resolve();
      },
      obterBalancoParaAtualizacao(idBalanco) {
        loading.exibir();

        return this.$store
          .dispatch('balanco/obterDadosParaAtualizarBalanco', {
            idBalanco: idBalanco,
          })
          .then((jsonResponse) => {
            this.dadosBalancoParaAlterar = jsonResponse;
            return Promise.resolve(jsonResponse);
          })
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          })
          .finally(() => {
            loading.ocultar();
          });
      },
      ordenarEFiltrarEstrutura(array) {
        return array
          .sort((a, b) => {
            var nameA = a.Descricao.toLowerCase(),
              nameB = b.Descricao.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          .filter((estrutura) => {
            return !(this.controleTela.BloqueiaEstruturaMercadologicaIntegrada && estrutura.Integrado);
          })
          .filter((estrutura) => {
            return estrutura.Ativo == undefined ? true : estrutura.Ativo;
          });
      },
      voltarParaListagem() {
        swal.exibirMensagemComPergunta(
          'Atenção',
          'Ao cancelar, as alterações deste cadastro serão perdidas. Deseja mesmo cancelar?',
          (cancelarCadastro) => {
            if (cancelarCadastro) this.$router.push({ name: 'PainelBalancos' });
          },
        );
      },
      montarCommandDadosBalanco() {
        var commandDTO = [
          this.$refs['dadosGerais'].obterCommandDTO(),
          this.$refs['estruturaMercadologica'].obterCommandDTO(),
        ].reduce((novaPropriedade, objeto) => {
          Object.keys(objeto).forEach((nomePropriedadeObjeto) => {
            novaPropriedade[nomePropriedadeObjeto] = objeto[nomePropriedadeObjeto];
          });
          return novaPropriedade;
        }, {});

        commandDTO = { ...commandDTO, UtilizaConferenciaEmEtapas: this.permissoesUsuario.UtilizaConferenciaEmEtapas };

        return commandDTO;
      },
      cadastrarBalanco() {
        var commandDTO = this.montarCommandDadosBalanco();

        loading.exibir();

        return this.$store
          .dispatch('balanco/cadastrarBalanco', commandDTO)
          .then(() => {
            swal.exibirMensagemSucesso('Balanço cadastrado com sucesso!', () => {
              this.$router.push({ name: 'PainelBalancos' });
            });
            return Promise.resolve();
          })
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          })
          .finally(() => {
            loading.ocultar();
          });
      },
      alterarBalanco() {
        this.validarDadosCadastro().then(() => {
          var commandDTO = this.montarCommandDadosBalanco();
          commandDTO.IdEmpresa = this.$route.params.idEmpresa;
          loading.exibir();

          return this.$store
            .dispatch('balanco/atualizarBalanco', commandDTO)
            .then(() => {
              swal.exibirMensagemSucesso('Balanço alterado com sucesso!', () => {
                this.$router.push({ name: 'PainelBalancos' });
              });
              return Promise.resolve();
            })
            .catch((erro) => {
              swal.exibirMensagemErro(erro);
              return Promise.reject(erro);
            })
            .finally(() => {
              loading.ocultar();
            });
        });
      },
      validarDadosCadastro() {
        return Promise.all([this.$refs['dadosGerais'].validarDados()]).then((listaValidacoes) => {
          var sessoesComErro = listaValidacoes.reduce((cont, validacao) => {
            if (!validacao) {
              return cont + 1;
            }
            return cont;
          }, 0);
          if (sessoesComErro == 0) {
            return Promise.resolve(true);
          } else {
            return Promise.reject(sessoesComErro);
          }
        });
      },
      validarECadastrarBalanco() {
        this.validarDadosCadastro().then(() => {
          this.cadastrarBalanco();
        });
      },
      cadastrarSecao(descricaoNovaSecao) {
        const commandResult = { Descricao: descricaoNovaSecao };

        loading.exibir();

        this.$store
          .dispatch('balanco/cadastrarSecao', commandResult)
          .then((response) => {
            const data = response;
            this.obterDadosIniciaisCadastroBalancos();
          })
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          })
          .finally(() => {
            loading.ocultar();
          });
      },
      async carregaLabelNiveis() {
        await this.$store
          .dispatch('localizacao/obterLabelNiveis')
          .then()
          .catch((erro) => {
            swal.exibirMensagemErro(erro);
          });
      },
    },
  };
</script>

<style scoped>
  .overflow-overlay {
    overflow: auto;
    overflow: overlay;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .fixed {
    position: fixed;
    right: 0px;
  }

  .tabela-background thead {
    background-color: #5b2e90;
    color: white;
  }

  .tabela-background {
    background-color: #5b2e90;
    color: white;
  }

  .bold {
    font-weight: bold;
  }

  .fs-80 {
    font-size: 80%;
  }
</style>
