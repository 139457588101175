<template>
  <div class="container-fluid card-panel bg-white shadow-hover shadow-sm mt-2" :id="idSecao">
    <div class="row">
      <div class="col-12">
        <h1 class="h1">Estrutura mercadológica</h1>
      </div>
    </div>

    <div v-if="estruturaMercadologica.Setores && estruturaMercadologica.Setores.length > 0" ref="setor" class="row">
      <div class="col-12">
        <label class="label-cadastro-balancos">{{ legendasNomes.Setor }}</label>
        <VueMultiselect
          id="select-setor"
          v-model="dadosSelecionados.setores"
          :options="setoresFiltrados"
          :multiple="true"
          :close-on-select="false"
          :allow-empty="true"
          :group-select="true"
          group-values="estruturasMercadologicas"
          group-label="labelGroup"
          :clear-on-select="true"
          :preserve-search="false"
          placeholder="Selecione"
          :custom-label="customLabel"
          selected-label="Selecionado"
          select-label="Selecionar"
          deselect-label="Remover"
          label="Descricao"
          track-by="Id"
          :preselect-first="false"
          select-group-label="Clique para Selecionar Todos"
          deselect-group-label="Clique para Remover Todos"
        >
        <template slot="selection" slot-scope="select">
            <span class="multiselect__single" v-if="select.values.length == 1 &amp;&amp; !select.isOpen">
              <div v-for="item in select.values" v-bind:key="item.Id" style="float: left">
                {{ item.Descricao + ' (' + item.Id + ')' }}
              </div>
            </span>
            <span class="multiselect__single" v-if="select.values.length > 1 &amp;&amp; !select.isOpen">{{ select.values.length }} registros selecionados</span>
          </template>
          <span slot="noResult">Nenhum registro encontrado</span>
          <span slot="noOptions">Selecione um registro</span>
        </VueMultiselect>
      </div>
    </div>

    <div v-if="estruturaMercadologica.Linhas && estruturaMercadologica.Linhas.length > 0" ref="linha" class="row">
      <div class="col-12">
        <label class="label-cadastro-balancos">{{ legendasNomes.Linha }}</label>
        <VueMultiselect
          id="select-linha"
          v-model="dadosSelecionados.linhas"
          :options="linhasFiltradas"
          :multiple="true"
          :close-on-select="false"
          :allow-empty="true"
          :group-select="true"
          group-values="estruturasMercadologicas"
          group-label="labelGroup"
          :clear-on-select="true"
          :preserve-search="false"
          placeholder="Selecione"
          :custom-label="customLabel"
          selected-label="Selecionado"
          select-label="Selecionar"
          deselect-label="Remover"
          label="Descricao"
          track-by="Id"
          :preselect-first="false"
          select-group-label="Clique para Selecionar Todos"
          deselect-group-label="Clique para Remover Todos"
        >
        <template slot="selection" slot-scope="select">
            <span class="multiselect__single" v-if="select.values.length == 1 &amp;&amp; !select.isOpen">
              <div v-for="item in select.values" v-bind:key="item.Id" style="float: left">
                {{ item.Descricao + ' (' + item.Id + ')' }}
              </div>
            </span>
            <span class="multiselect__single" v-if="select.values.length > 1 &amp;&amp; !select.isOpen">{{ select.values.length }} registros selecionados</span>
          </template>
          <span slot="noResult">Nenhum registro encontrado</span>
          <span slot="noOptions">Selecione um registro</span>
        </VueMultiselect>
      </div>
    </div>

    <div v-if="estruturaMercadologica.Marcas && estruturaMercadologica.Marcas.length > 0" ref="marca" class="row">
      <div class="col-12">
        <label class="label-cadastro-balancos">{{ legendasNomes.Marca }}</label>
        <VueMultiselect
          id="select-marca"
          v-model="dadosSelecionados.marcas"
          :options="marcasFiltradas"
          :multiple="true"
          :close-on-select="false"
          :allow-empty="true"
          :group-select="true"
          group-values="estruturasMercadologicas"
          group-label="labelGroup"
          :clear-on-select="true"
          :preserve-search="false"
          placeholder="Selecione"
          :custom-label="customLabel"
          selected-label="Selecionado"
          select-label="Selecionar"
          deselect-label="Remover"
          label="Descricao"
          track-by="Id"
          :preselect-first="false"
          select-group-label="Clique para Selecionar Todos"
          deselect-group-label="Clique para Remover Todos"
        >
          <template slot="selection" slot-scope="select">
            <span class="multiselect__single" v-if="select.values.length == 1 &amp;&amp; !select.isOpen">
              <div v-for="item in select.values" v-bind:key="item.Id" style="float: left">
                {{ item.Descricao + ' (' + item.Id + ')' }}
              </div>
            </span>
            <span class="multiselect__single" v-if="select.values.length > 1 &amp;&amp; !select.isOpen">{{ select.values.length }} registros selecionados</span>
          </template>
          <span slot="noResult">Nenhum registro encontrado</span>
          <span slot="noOptions">Selecione um registro</span>
        </VueMultiselect>
      </div>
    </div>

    <div v-if="estruturaMercadologica.Colecoes && estruturaMercadologica.Colecoes.length > 0" ref="colecao" class="row">
      <div class="col-12">
        <label class="label-cadastro-balancos">{{ legendasNomes.Colecao }}</label>
        <VueMultiselect
          id="select-colecao"
          v-model="dadosSelecionados.colecoes"
          :options="colecoesFiltradas"
          :multiple="true"
          :close-on-select="false"
          :allow-empty="true"
          :group-select="true"
          group-values="estruturasMercadologicas"
          group-label="labelGroup"
          :clear-on-select="true"
          :preserve-search="false"
          placeholder="Selecione"
          :custom-label="customLabel"
          selected-label="Selecionado"
          select-label="Selecionar"
          deselect-label="Remover"
          label="Descricao"
          track-by="Id"
          :preselect-first="false"
          select-group-label="Clique para Selecionar Todos"
          deselect-group-label="Clique para Remover Todos"
        >
          <template slot="selection" slot-scope="select">
            <span class="multiselect__single" v-if="select.values.length == 1 &amp;&amp; !select.isOpen">
              <div v-for="item in select.values" v-bind:key="item.Id" style="float: left">
                {{ item.Descricao + ' (' + item.Id + ')' }}
              </div>
            </span>
            <span class="multiselect__single" v-if="select.values.length > 1 &amp;&amp; !select.isOpen">{{ select.values.length }} registros selecionados</span>
          </template>
          <span slot="noResult">Nenhum registro encontrado</span>
          <span slot="noOptions">Selecione um registro</span>
        </VueMultiselect>
      </div>
    </div>

    <div v-if="estruturaMercadologica.Grades1 && estruturaMercadologica.Grades1.length > 0" ref="grade1" class="row">
      <div class="col-12">
        <label class="label-cadastro-balancos">{{ legendasNomes.Grade1 }}</label>
        <VueMultiselect
          id="select-grade1"
          v-model="dadosSelecionados.grade1"
          :options="tamanhoFiltrados"
          :multiple="true"
          :close-on-select="false"
          :allow-empty="true"
          :group-select="true"
          group-values="estruturasMercadologicas"
          group-label="labelGroup"
          :clear-on-select="true"
          :preserve-search="false"
          placeholder="Selecione"
          :custom-label="customLabel"
          selected-label="Selecionado"
          select-label="Selecionar"
          deselect-label="Remover"
          label="Descricao"
          track-by="Id"
          :preselect-first="false"
          select-group-label="Clique para Selecionar Todos"
          deselect-group-label="Clique para Remover Todos"
        >
          <template slot="selection" slot-scope="select">
            <span class="multiselect__single" v-if="select.values.length == 1 &amp;&amp; !select.isOpen">
              <div v-for="item in select.values" v-bind:key="item.Id" style="float: left">
                {{ item.Descricao + ' (' + item.Id + ')' }}
              </div>
            </span>
            <span class="multiselect__single" v-if="select.values.length > 1 &amp;&amp; !select.isOpen">{{ select.values.length }} registros selecionados</span>
          </template>
          <span slot="noResult">Nenhum registro encontrado</span>
          <span slot="noOptions">Selecione um registro</span>
        </VueMultiselect>
      </div>
    </div>

    <div v-if="estruturaMercadologica.Grades2 && estruturaMercadologica.Grades2.length > 0" ref="grade2" class="row">
      <div class="col-12">
        <label class="label-cadastro-balancos">{{ legendasNomes.Grade2 }}</label>
        <VueMultiselect
          id="select-grade2"
          v-model="dadosSelecionados.grade2"
          :options="coresFiltradas"
          :multiple="true"
          :close-on-select="false"
          :allow-empty="true"
          :group-select="true"
          group-values="estruturasMercadologicas"
          group-label="labelGroup"
          :clear-on-select="true"
          :preserve-search="false"
          placeholder="Selecione"
          :custom-label="customLabel"
          selected-label="Selecionado"
          select-label="Selecionar"
          deselect-label="Remover"
          label="Descricao"
          track-by="Id"
          :preselect-first="false"
          select-group-label="Clique para Selecionar Todos"
          deselect-group-label="Clique para Remover Todos"
        >
          <template slot="selection" slot-scope="select">
            <span class="multiselect__single" v-if="select.values.length == 1 &amp;&amp; !select.isOpen">
              <div v-for="item in select.values" v-bind:key="item.Id" style="float: left">
                {{ item.Descricao + ' (' + item.Id + ')' }}
              </div>
            </span>
            <span class="multiselect__single" v-if="select.values.length > 1 &amp;&amp; !select.isOpen">{{ select.values.length }} registros selecionados</span>
          </template>
          <span slot="noResult">Nenhum registro encontrado</span>
          <span slot="noOptions">Selecione um registro</span>
        </VueMultiselect>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import VueMultiselect from 'vue-multiselect';

  export default {
    name: 'EstruturaMercadologicaBalancos',

    components: {
      VueMultiselect,
    },

    props: {
      estruturaMercadologica: {
        type: Object,
        default: () => {},
      },
      legendasNomes: {
        type: Object,
        default: () => {},
      },
      alteracaoDeBalanco: {
        type: Boolean,
        default: false,
      },
      dadosBalancoParaAlterar: {
        type: Object,
        default: null,
      },
      idSecao: {
        type: String,
        default: 'secaoEstruturaMercadologica',
      },
    },

    data() {
      return {
        dadosSelecionados: {
          setores: [],
          linhas: [],
          marcas: [],
          colecoes: [],
          grade1: [],
          grade2: [],
        },
      };
    },

    computed: {
      linhasFiltradas() {
        const estruturasFiltradas = this.filtrarEstruturaMercadologica(
          this.estruturaMercadologica.Linhas,
          this.dadosSelecionados.linhas,
          this.estruturaMercadologica.Setores,
          this.dadosSelecionados.setores,

        );

        return estruturasFiltradas;
      },
      setoresFiltrados(){
        const estruturaFiltrada = this.filtrarEstruturaMercadologica(
          this.estruturaMercadologica.Setores,
          [], [], []
        );

        return estruturaFiltrada;        
      },    

      marcasFiltradas() {
        const estruturasFiltradas = this.filtrarEstruturaMercadologica(
          this.estruturaMercadologica.Marcas,
          this.dadosSelecionados.marcas,
          this.estruturaMercadologica.Linhas,
          this.dadosSelecionados.linhas,
        );
        return estruturasFiltradas;
      },
      colecoesFiltradas() {
        const estruturasFiltradas = this.filtrarEstruturaMercadologica(
          this.estruturaMercadologica.Colecoes,
          this.dadosSelecionados.colecoes,
          this.estruturaMercadologica.Marcas,
          this.dadosSelecionados.marcas,
        );
        return estruturasFiltradas;
      },
      tamanhoFiltrados(){
        const estruturaFiltrada = this.filtrarEstruturaMercadologica(
          this.estruturaMercadologica.Grades1,
          [], [], []
        );

        return estruturaFiltrada;        
      },
      coresFiltradas() {
        const estruturasFiltradas = this.filtrarEstruturaMercadologica(
          this.estruturaMercadologica.Grades2,
          this.dadosSelecionados.grade2,
          this.estruturaMercadologica.Marcas,
          this.dadosSelecionados.marcas,
        );
        return estruturasFiltradas;
      },
    },

    watch: {
      dadosBalancoParaAlterar() {
        this.setaDadosParaBalancoAlterar();
      },
    },

    methods: {
      obterCommandDTO() {
        const commandResult = {};

        const { setores, linhas, marcas, colecoes, grade1, grade2 } = this.dadosSelecionados;

        commandResult.IdsSetores = setores.map(({ Id }) => Id);
        commandResult.IdsLinhas = linhas.map(({ Id }) => Id);
        commandResult.IdsMarcas = marcas.map(({ Id }) => Id);
        commandResult.IdsColecoes = colecoes.map(({ Id }) => Id);
        commandResult.IdsGrades1 = grade1.map(({ Id }) => Id);
        commandResult.IdsGrades2 = grade2.map(({ Id }) => Id);

        return commandResult;
      },
      customLabel({ Descricao, Id }) {
        return `${Descricao} (${Id})`;
      },
      filtrarEstruturaMercadologica(
        estruturas,
        dadosSelecionadosEstruturas,
        estruturasAssociadas,
        dadosSelecionadosEstruturasAssociadas,
      ) {
        let estruturasFiltradas = [];

        if(dadosSelecionadosEstruturas.length == 0 && estruturasAssociadas.length == 0 && dadosSelecionadosEstruturasAssociadas.length == 0){
          estruturasFiltradas = estruturas;
          
        }else if (dadosSelecionadosEstruturasAssociadas.length == 0) {
          estruturasFiltradas = estruturas.filter((estrutura) => {
            return estrutura.IdsAssociados.length == 0;
          });
        } else {
          estruturasFiltradas = estruturas.filter((estruturaFiltrada) => {
            let flag = false;

            if (estruturaFiltrada.IdsAssociados.length == 0) return true;

            for (let estruturaAssociada of dadosSelecionadosEstruturasAssociadas) {
              if (estruturaFiltrada.IdsAssociados.includes(estruturaAssociada.Id)) flag = true;
              if (flag) break;
            }

            return flag;
          });
        }

        if (dadosSelecionadosEstruturas.length != 0) {
          dadosSelecionadosEstruturas.forEach(({ Id }) => {
            const indexEstruturaSelecionada = estruturasFiltradas.findIndex((estruturaFiltrada) => estruturaFiltrada.Id === Id);
            if (indexEstruturaSelecionada == -1) dadosSelecionadosEstruturas.splice(indexEstruturaSelecionada, 1);
          });
        }

        const estruturasMercadologicasGrupo = [];

        const selecionarTodosGrupo = {
          labelGroup: 'Selecionar Todos',
          estruturasMercadologicas: estruturasFiltradas
        };

        estruturasMercadologicasGrupo.push(selecionarTodosGrupo);

        return estruturasMercadologicasGrupo;
      },
      setaDadosParaBalancoAlterar() {
        if (this.alteracaoDeBalanco && this.dadosBalancoParaAlterar) {
          this.dadosSelecionados.setores = this.estruturaMercadologica.Setores.filter(({ Id }) =>
            this.dadosBalancoParaAlterar.IdsSetores.includes(Id),
          );
          this.dadosSelecionados.linhas = this.estruturaMercadologica.Linhas.filter(({ Id }) =>
            this.dadosBalancoParaAlterar.IdsLinhas.includes(Id),
          );
          this.dadosSelecionados.marcas = this.estruturaMercadologica.Marcas.filter(({ Id }) =>
            this.dadosBalancoParaAlterar.IdsMarcas.includes(Id),
          );
          this.dadosSelecionados.colecoes = this.estruturaMercadologica.Colecoes.filter(({ Id }) =>
            this.dadosBalancoParaAlterar.IdsColecoes.includes(Id),
          );
          this.dadosSelecionados.grade1 = this.estruturaMercadologica.Grades1.filter(({ Id }) =>
            this.dadosBalancoParaAlterar.IdsGrade1.includes(Id),
          );
          this.dadosSelecionados.grade2 = this.estruturaMercadologica.Grades2.filter(({ Id }) =>
            this.dadosBalancoParaAlterar.IdsGrade2.includes(Id),
          );
        }
      },
    },

    mounted() {
      this.setaDadosParaBalancoAlterar();
    },
  };
</script>

<style>
  .label-cadastro-balancos {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }

  .multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: left;
    color: rgb(53, 73, 94);
  }

  .multiselect__tag {
    background: #5b2e90 !important;
  }
  /* .multiselect_option--group:houver{
   flood-color: blue;
  }
  */
</style>
