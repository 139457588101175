<template>
  <ul class="nav flex-column">
    <li>
      <div class="mt-3 mb-3 w-90">
        <div
          class="d-flex flex-column flex-wrap justify-content-center align-content-center fonte-box shadow-hover h-100 w-100 m-0"
        >
          <div class="d-flex justify-content-center w-100">
            <div class="d-flex flex-row flex-wrap justify-content-center align-content-center circle align-items-center">
              <i class="fa fa-cubes" style="font-size: 35px"></i>
            </div>
          </div>
          <div class="d-flex justify-content-center w-100 mt-2">
            <div class="text-uppercase text-white">Cadastro de Balanço</div>
          </div>
        </div>
      </div>
    </li>
    <li class="nav-item" v-for="(secao, index) in listaDeSecoes" :key="`${index}-${secao.Id}`" v-show="secao.Visivel">
      <a class="text-uppercase" href="javascript:void(0)" v-scroll-to="secao.Id">{{ secao.Titulo }}</a>
    </li>
  </ul>
</template>

<script>
  import Vue from 'vue';
  import VueScrollTo from 'vue-scrollto';

  Vue.use(VueScrollTo, {
    container: '#containerCadastroBalancos',
    duration: 500,
    easing: 'ease',
    offset: 0,
    force: true,
    cancelable: false,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
  });

  export default {
    name: 'NavegacaoLateralCadastroBalanco',

    props: {
      listaDeSecoes: {
        type: Array,
        default: () => [],
      },
    },
  };
</script>

<style scoped>
  .nav-imagem {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .fonte-box {
    background: #5b2e90;
    width: 300px;
    height: 100px;
    padding: 15px;
    margin: 20px;
    border: 1px solid black;
    cursor: pointer;
    -webkit-box-shadow: -2px 6px 10px -1px rgba(0, 0, 0, 0.45);
    -moz-box-shadow: -2px 6px 10px -1px rgba(0, 0, 0, 0.45);
    box-shadow: -2px 6px 10px -1px rgba(0, 0, 0, 0.45);
    opacity: 1 !important;
    transition: 0.7s;
  }
  .fonte-box i {
    color: #5b2e90;
    font-size: 50px;
  }
  .fonte-box .circle {
    height: 70px;
    width: 70px;
    background-color: white;
    border-radius: 50%;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    background: rgba(226, 226, 226, 1);
    background: -moz-linear-gradient(
      -45deg,
      rgba(226, 226, 226, 1) 0%,
      rgba(219, 219, 219, 1) 50%,
      rgba(209, 209, 209, 1) 51%,
      rgba(254, 254, 254, 1) 100%
    );
    background: -webkit-gradient(
      left top,
      right bottom,
      color-stop(0%, rgba(226, 226, 226, 1)),
      color-stop(50%, rgba(219, 219, 219, 1)),
      color-stop(51%, rgba(209, 209, 209, 1)),
      color-stop(100%, rgba(254, 254, 254, 1))
    );
    background: -webkit-linear-gradient(
      -45deg,
      rgba(226, 226, 226, 1) 0%,
      rgba(219, 219, 219, 1) 50%,
      rgba(209, 209, 209, 1) 51%,
      rgba(254, 254, 254, 1) 100%
    );
    background: -o-linear-gradient(
      -45deg,
      rgba(226, 226, 226, 1) 0%,
      rgba(219, 219, 219, 1) 50%,
      rgba(209, 209, 209, 1) 51%,
      rgba(254, 254, 254, 1) 100%
    );
    background: -ms-linear-gradient(
      -45deg,
      rgba(226, 226, 226, 1) 0%,
      rgba(219, 219, 219, 1) 50%,
      rgba(209, 209, 209, 1) 51%,
      rgba(254, 254, 254, 1) 100%
    );
    background: linear-gradient(
      135deg,
      rgba(226, 226, 226, 1) 0%,
      rgba(219, 219, 219, 1) 50%,
      rgba(209, 209, 209, 1) 51%,
      rgba(254, 254, 254, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#fefefe', GradientType=1);
  }
</style>
