<template>
  <div class="w-100">
    <div class="d-flex justify-content-start m-0">
      <div v-for="(nivel, index) in niveis" :key="index" class="mr-1">
        <div ref="localizacao" class="col-sm-12 p-0 m-0">
          <VueMultiselect
            class="vue-multiselect-loc"
            v-model="localizacaoSelecionada[nivel.Nivel]"
            :options="localizacoes.filter((f) => f.Nivel == nivel.Nivel)"
            :multiple="false"
            :close-on-select="true"
            :allow-empty="true"
            :placeholder="nivel.Nivel + '-' + nivel.Nome"
            :option-height="10"
            selected-label="Selecionado"
            select-label="Selecionar"
            deselect-label="Remover"
            label="Sigla"
            track-by="Sigla"
            :preselect-first="false"
            @open="buscaNivelLocalizacao(nivel.Nivel)"
            @select="onSelect"
            @remove="onRemove"
            :disabled="nivel.Nivel > 1 && !localizacaoSelecionada[nivel.Nivel - 1]"
          >
            <span slot="afterList" v-if="carregandoListaLocalizacoes" class="text-center">
              <small>Carregando...</small>
              <i class="fas fa-sync fa-spin"></i>
            </span>
            <span slot="noOptions">Selecione um registro</span>
            <span slot="noResult">Nenhum registro encontrado</span>
          </VueMultiselect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import swal from '@/common/alerts';
  import VueMultiselect from 'vue-multiselect';
  import '@/common/directives';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      VueMultiselect,
    },
    props: {
      idDeposito: {
        type: Number,
        required: true,
      },
      idSecao: {
        type: Number,
        required: true,
      },
      localizacaoSelecionada: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        siglas: [],
        carregandoListaLocalizacoes: false,
        localizacoes: [],
      };
    },
    created() {},
    computed: {
      ...mapGetters('localizacao', {
        niveis: 'listaLabelNiveis',
      }),
    },
    methods: {
      onSelect(value) {
        let siglaOld = this.localizacaoSelecionada[value.Nivel] && this.localizacaoSelecionada[value.Nivel].Sigla;
        if (siglaOld && value.Sigla !== siglaOld) {
          this.onRemove(value);
        } else {
          this.selecionaLocalizacao(value);
        }
      },
      onRemove(value) {
        let qtdeNiveis = Object.keys(this.localizacaoSelecionada).length;
        for (var i = 1; i <= qtdeNiveis; i++) {
          if (i >= value.Nivel) {
            delete this.localizacaoSelecionada[i];
          }
        }
        this.selecionaLocalizacao(value);
      },
      selecionaLocalizacao(value) {
        this.$emit('input', { ...value, idSecao: this.idSecao });
      },
      async buscaNivelLocalizacao(nivel) {
        let siglas = [];
        if (nivel > 1) {
          for (let i = 1; i < nivel; i++) {
            siglas.push(this.localizacaoSelecionada[i]?.Sigla);
          }
        }

        let params = {
          IdDeposito: this.idDeposito,
          Siglas: siglas,
          SiglaCompleta: false,
        };
        this.carregandoListaLocalizacoes = true;
        await this.$store
          .dispatch('localizacao/obterLocalizacoesDeposito', params)
          .then((response) => {
            this.localizacoes = response.Localizacoes.map((loc) => {
              return {
                Nivel: response.Nivel,
                IdLocalizacao: loc.IdLocalizacao,
                Nome: loc.Nome,
                Sigla: loc.Sigla,
              };
            });
          })
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          });
        this.carregandoListaLocalizacoes = false;
      },
    },
  };
</script>

<style lang="scss" scope>
  .vue-multiselect-loc .multiselect--disabled .multiselect__current,
  .multiselect--disabled .multiselect__select,
  .multiselect__option--disabled {
    background: #ffffff !important;
    color: #a6a6a6;
    margin-bottom: 0px;
    padding-top: 0px;
  }
  .vue-multiselect-loc .multiselect__placeholder {
    color: #adadad;
    display: inline-block;
    margin: 0px;
    padding: 0px 0px 2px 0px;
  }
  .vue-multiselect-loc .multiselect__tags {
    height: auto;
    display: block;
    padding: 0px 26px 0 4px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    height: 24px;
  }
  .vue-multiselect-loc .multiselect__select {
    position: absolute;
    width: 25px;
    height: 20px;
    right: 1px;
    top: 1px;
    padding: 8px 0px 0px 2px;
    text-align: center;
    transition: transform 0.2s ease;
  }
</style>
